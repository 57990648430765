import logo from "./logo.svg";
import "./App.css";
import { Navbar } from "./component/Navbar";
import { Hero } from "./component/Hero";
import { Main } from "./component/Main";
import { Footer } from "./component/Footer";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <Main />
      <Footer />
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center active"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      </>
  );
}

export default App;
