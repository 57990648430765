import React from "react";

export const Hero = () => {
  return (
    <section id="hero">
      <div className="hero-container">
        <h3>
          Welcome to <strong>Techmet Business Solutions</strong>
        </h3>
        <h1>Where Technology Meets you.</h1>
        <h2>
          We are team of talented Developers making ERP solutions for you
        </h2>
        <a href="#about" className="btn-get-started scrollto">
          Get Started
        </a>
      </div>
    </section>
  );
};
