import React from "react";

export const Service = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <h3>
            We do offer awesome <span>Services</span>
          </h3>
          <p>
            Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
            adipisci expedita at voluptas atque vitae autem.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxl-dribbble" />
              </div>
              <h4 className="title">
                <a href="">ODOO IMPLEMENTATION</a>
              </h4>
              <p className="description">
                it is the service of implementing modules to maintain the
                business workflow at a consistent pace. With over 3000 apps your
                business functions will now run smoothly. After complete study
                of company size, requirement, modules and number of users, we
                decide its time completion. Hiring us will ensure the perfection
                in your organisation.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-file" />
              </div>
              <h4 className="title">
                <a href="">ODOO CONSULTANCY</a>
              </h4>
              <p className="description">
                it ensures the success of odoo project. From implementing the
                ERP solution and developing the softwares to post-launch work,
                our experts will support you at every stage of our relationship.
                Our organisation creates a simple workflow to enlighten the
                customisation that guarantees the success.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-tachometer" />
              </div>
              <h4 className="title">
                <a href="">ODOO TRAINING</a>
              </h4>
              <p className="description">
                learning is eternal. Our trainers can teach/guide you on how
                odoo works? Our training is open to students, professionals,
                developers, integrators, and as well as to our clients. Let’s
                resolve your professional challenges that you face in business
                environment. We have designed two programs for you : technical
                and functional.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-world" />
              </div>
              <h4 className="title">
                <a href="">ODOO MODULE DEVELOPMENT</a>
              </h4>
              <p className="description">
                odoo starts and ends with modules. Modules add/alter/extend a
                new logic to an excising business. It’s the essence of the ERP
                solutions. One of the distinct characteristics of odoo is its
                modular architecture. More than 35 modules cover all business
                functions
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
