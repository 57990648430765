import React from 'react'
import { About } from './About'
import { ContactUs } from './ContactUs'
import { Cta } from './Cta'
import { Featurs } from './Featurs'
import { Portfolia } from './Portfolia'
import { Pricing } from './Pricing'
import { Service } from './Service'
import { Team } from './Team'

export const Main = () => {
  return (
    <main id="main">
        <About/>
        <Service/>
        {/* <Featurs/> */}
        <Cta/>
        <Portfolia/>
        {/* <Pricing/>
        <Team/> */}
        {/* <ContactUs/> */}
    </main>
  )
}
