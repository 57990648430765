import React from "react";

export const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="section-title">
          <h2>About</h2>
          <h3>
            Learn More <span>About Us</span>
          </h3>
          <p>
            it is the service of implementing modules to maintain the business
            workflow at a consistent pace. With over 3000 apps your business
            functions will now run smoothly. After complete study of company
            size, requirement, modules and number of users, we decide its time
            completion. Hiring us will ensure the perfection in your
            organisation.
          </p>
        </div>
        <div className="row content">
          <div className="col-lg-6">
            <p>
            
  </p>
            <ul>
              <li>
                <i className="ri-check-double-line" /> ODOO CONSULTANCY

              </li>
              <li>
                <i className="ri-check-double-line" /> ODOO TRAINING

              </li>
              <li>
                <i className="ri-check-double-line" /> ODOO MODULE DEVELOPMENT

              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
            ODOO UPDATE
            </p>
            <p>
            ODOO MIGRATIONS
            </p>
            <a href="#" className="btn-learn-more">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
